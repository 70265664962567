<script>
  import { fetchCreatePropertyViolationPolicy } from "../../api";
  import { createEventDispatcher } from "svelte";

  export let property;
  let submittable = false;
  let submitting = false;
  const events = createEventDispatcher();

  // save the first one we get

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (!property || submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    const json = await fetchCreatePropertyViolationPolicy(
      property.id,
      formData
    );

    submitting = submittable = false;
    form.reset();

    // event created item
    events("change", json);
  }
</script>

<form
  on:change={change}
  on:input={change}
  on:submit={submit}
  class="edit property"
>
  <input type="hidden" name="scope" value={property?.id || ""} />
  <fieldset>
    <ul>
      <li>
        <label for="property-violations-threshold-alert">Alert text</label>
        <input
          id="property-violations-threshold-alert"
          type="text"
          name="title"
          required
          placeholder="required"
        />
      </li>
      <li>
        <label for="property-violations-threshold-count">Trigger on</label>
        <select id="property-violations-threshold-count" name="count">
          <!-- <option value="-1">disabled</option> -->
          <option value="1">any violation</option>
          <option value="2">2+ violations</option>
          <option value="3">3+ violations</option>
          <option value="4">4+ violations</option>
          <option value="5">5+ violations</option>
          <option value="6">6+ violations</option>
          <option value="7">7+ violations</option>
          <option value="8">8+ violations</option>
          <option value="9">9+ violations</option>
          <option value="10">10+ violations</option>
        </select>
      </li>
      <li>
        <label for="property-violations-threshold-period">Period</label>
        <select id="property-violations-threshold-period" name="period">
          <option value="P7D">last 7 days</option>
          <option value="P1W">calendar week</option>
          <option value="P14D">last 14 days</option>
          <option value="P21D">last 21 days</option>
          <option value="P30D">last 30 days</option>
          <option value="P1M">calendar month</option>
          <option value="P45D">last 45 days</option>
          <option value="P{7 * 8}D">last {7 * 8} days</option>
          <option value="P60D">last 60 days</option>
          <option value="P90D">last 90 days</option>
          <option value="P1Q">calendar quarter</option>
          <option value="P120D">last 120 days</option>
          <option value="P180D">last 180 days</option>
          <option value="P1H">calendar half</option>
          <option value="P365D">last 365 days</option>
          <option value="P1Y">calendar year</option>
          <option value="PT256204778H48M5S">all time / indefinite</option>
        </select>
      </li>
      <li>
        <label for="property-violations-threshold-fine">Fine ($)</label>
        <input
          id="property-violations-threshold-fine"
          type="number"
          name="dollars"
          min="0.00"
          step="0.01"
          placeholder="0.00"
        />
      </li>
    </ul>
  </fieldset>

  <footer>
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Saving" : "Add"}</button
    >
  </footer>
</form>
