<script>
  import { fetchCreatePolicyMeter } from "../api";
  import {
    parse,
    startOfDay,
    startOfHour,
    addDays,
    addMinutes,
  } from "date-fns";
  import { format } from "date-fns-tz";
  import { policies } from "../stores";

  export let policy = null;

  let applies = false;
  let days = false;
  let type = "concurrent";
  let subjects = false;

  $: concurrent = type == "concurrent";

  let submittable = false;
  let submitting = false;

  function change(e) {
    submittable = e.target.form.checkValidity();
  }

  function setDefaults() {
    days = applies = submitting = submittable = subjects = true;
    days = applies = submitting = submittable = subjects = false;
    (type = true) && (type = "concurrent");
  }

  async function submit(e) {
    e.preventDefault();

    if (!policy || submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    await fetchCreatePolicyMeter(policy, formData);

    form.reset();

    setDefaults();
  }

  let scheduledInterval = [startOfHour(new Date()), startOfHour(new Date())];

  function startDate(e) {
    scheduledInterval[0] = parse(
      `${e.target.value}T${format(scheduledInterval[0], "HH:mm")}`,
      "yyyy-MM-dd'T'HH:mm",
      new Date(e.target.value)
    );
    scheduledInterval = scheduledInterval;
  }

  function startTime(e) {
    scheduledInterval[0] = parse(
      `${format(scheduledInterval[0], "yyyy-MM-dd")}T${e.target.value}`,
      "yyyy-MM-dd'T'HH:mm",
      scheduledInterval[0]
    );
    scheduledInterval = scheduledInterval;
  }

  function endDate(e) {
    scheduledInterval[1] = parse(
      `${e.target.value}T${format(scheduledInterval[1], "HH:mm")}`,
      "yyyy-MM-dd'T'HH:mm",
      new Date(e.target.value)
    );
    scheduledInterval = scheduledInterval;
  }

  function endTime(e) {
    scheduledInterval[1] = parse(
      `${format(scheduledInterval[1], "yyyy-MM-dd")}T${e.target.value}`,
      "yyyy-MM-dd'T'HH:mm",
      scheduledInterval[1]
    );
    scheduledInterval = scheduledInterval;
  }
</script>

{#if policy}
  <form on:change={change} on:input={change} on:submit={submit}>
    {#if "valid" === applies}
      <input
        type="hidden"
        name="valid"
        value="{format(scheduledInterval[0], "yyyy-MM-dd'T'HH:mm:ssXXX", {
          timeZone: policy.timezone,
        })}/{format(scheduledInterval[1], "yyyy-MM-dd'T'HH:mm:ssXXX", {
          timeZone: policy.timezone,
        })}"
      />
    {/if}
    <fieldset>
      <ul>
        <li>
          <label for="new-meter-policy-maximum">Limit to</label><input
            id="new-meter-policy-maximum"
            name="concurrent"
            type="number"
            min="1"
            required
            placeholder="required"
            value="1"
          />
        </li>
        <li>
          <label for="new-meter-spacing">Spacing</label>
          <select name="spacing" id="new-meter-spacing">
            <option value="">none</option>
            <option value="PT1H">1 hour</option>
            <option value="PT24H">24 hours</option>
          </select>
        </li>
        <li>
          <label for="new-meter-policy-applies">Applies</label>
          <select
            id="new-meter-policy-applies"
            on:change={(e) => (applies = e.target.value)}
            on:blur={(e) => (applies = e.target.value)}
          >
            <option value="">all days & times</option>
            {#if concurrent}
              <option value="valid">specific start & end</option>
            {/if}
            <option value="partial">partial week…</option>
            <option value="daystimes">specific days & times…</option>
          </select>
        </li>
        {#if "partial" === applies}
          <li>
            <label for="new-meter-policy-start">Start</label>
            <select
              id="new-meter-policy-startday"
              name="valid.day.min"
              required
            >
              <option value="">--</option>
              <option value="0">Sunday</option>
              <option value="1">Monday</option>
              <option value="2">Tuesday</option>
              <option value="3">Wednesday</option>
              <option value="4">Thursday</option>
              <option value="5">Friday</option>
              <option value="6">Saturday</option>
            </select><input
              id="new-meter-policy-starttime"
              name="valid.time.min"
              type="time"
              required
              placeholder="required"
            />
          </li>
          <!-- <li><label for="new-meter-policy-starttime">Start time</label></li> -->
          <li>
            <label for="new-meter-policy-end">End</label>
            <select id="new-meter-policy-endday" name="valid.day.max" required>
              <option value="">--</option>
              <option value="0">Sunday</option>
              <option value="1">Monday</option>
              <option value="2">Tuesday</option>
              <option value="3">Wednesday</option>
              <option value="4">Thursday</option>
              <option value="5">Friday</option>
              <option value="6">Saturday</option>
            </select><input
              id="new-meter-policy-endtime"
              name="valid.time.max"
              type="time"
              required
              placeholder="required"
            />
          </li>
          <!-- <li><label for="new-meter-policy-endtime">End time</label></li> -->
        {:else if "daystimes" === applies}
          <li>
            <label for="new-meter-policy-days">Days</label>
            <select
              id="new-meter-policy-days"
              on:change={(e) => (days = e.target.value === "true")}
              on:blur={(e) => (days = e.target.value === "true")}
            >
              <option value="false">every day…</option>
              <option value="true">specific days…</option>
            </select>
            {#if days}
              <ul>
                <li>
                  <input
                    id="new-meter-policy-days-sun"
                    type="checkbox"
                    name="valid.days"
                    value="0"
                    checked
                  /><label for="new-meter-policy-days-sun">Sunday</label>
                </li>
                <li>
                  <input
                    id="new-meter-policy-days-mon"
                    type="checkbox"
                    name="valid.days"
                    value="1"
                    checked
                  /><label for="new-meter-policy-days-mon">Monday</label>
                </li>
                <li>
                  <input
                    id="new-meter-policy-days-tue"
                    type="checkbox"
                    name="valid.days"
                    value="2"
                    checked
                  /><label for="new-meter-policy-days-tue">Tuesday</label>
                </li>
                <li>
                  <input
                    id="new-meter-policy-days-wed"
                    type="checkbox"
                    name="valid.days"
                    value="3"
                    checked
                  /><label for="new-meter-policy-days-wed">Wednesday</label>
                </li>
                <li>
                  <input
                    id="new-meter-policy-days-thu"
                    type="checkbox"
                    name="valid.days"
                    value="4"
                    checked
                  /><label for="new-meter-policy-days-thu">Thursday</label>
                </li>
                <li>
                  <input
                    id="new-meter-policy-days-fri"
                    type="checkbox"
                    name="valid.days"
                    value="5"
                    checked
                  /><label for="new-meter-policy-days-fri">Friday</label>
                </li>
                <li>
                  <input
                    id="new-meter-policy-days-sat"
                    type="checkbox"
                    name="valid.days"
                    value="6"
                    checked
                  /><label for="new-meter-policy-days-sat">Saturday</label>
                </li>
              </ul>
            {/if}
          </li>

          <li>
            <label for="new-meter-policy-starttime">Start time</label>
            <input
              id="new-meter-policy-starttime"
              name="valid.time.min"
              type="time"
              required
              placeholder="required"
            />
          </li>
          <!-- <li><label for="new-meter-policy-starttime">Start time</label></li> -->
          <li>
            <label for="new-meter-policy-endtime">End time</label>
            <input
              id="new-meter-policy-endtime"
              name="valid.time.max"
              type="time"
              required
              placeholder="required"
            />
          </li>
        {:else if "valid" === applies}
          <li>
            <label>Starting</label><select
              value={format(scheduledInterval[0], "yyyy-MM-dd")}
              on:blur={startDate}
              on:change={startDate}
            >
              {#each Array.from( { length: 30 }, (v, i) => addDays(new Date(), i) ) as date}
                <option value={format(date, "yyyy-MM-dd")}
                  >{format(date, "EE MMM d")}</option
                >
              {/each}
            </select><select
              value={format(scheduledInterval[0], "HH:mm")}
              on:blur={startTime}
              on:change={startTime}
            >
              {#each Array.from( { length: 48 }, (v, i) => addMinutes(startOfDay(new Date()), i * 30) ) as date}
                <option value={format(date, "HH:mm")}
                  >{format(date, "h:mm a")}</option
                >
              {/each}
            </select>
          </li>
          <li>
            <label>Ending</label><select
              value={format(scheduledInterval[1], "yyyy-MM-dd")}
              on:blur={endDate}
              on:change={endDate}
            >
              {#each Array.from( { length: 30 }, (v, i) => addDays(new Date(), i) ) as date}
                <option value={format(date, "yyyy-MM-dd")}
                  >{format(date, "EE MMM d")}</option
                >
              {/each}
            </select><select
              value={format(scheduledInterval[1], "HH:mm")}
              on:blur={endTime}
              on:change={endTime}
            >
              {#each Array.from( { length: 48 }, (v, i) => addMinutes(startOfDay(new Date()), i * 30) ) as date}
                <option value={format(date, "HH:mm")}
                  >{format(date, "h:mm a")}</option
                >
              {/each}
            </select>
          </li>
        {/if}
        <li>
          <label for="new-meter-policy-title">Reason</label><input
            id="new-meter-policy-title"
            name="title"
            type="text"
            maxlength="200"
            placeholder="optional"
          />
        </li>
        <li>
          <label for="new-meter-tenant-subject">Policy</label>
          <select
            id="new-meter-tenant-subject"
            name="subject"
            value={subjects ? "" : policy.policy}
            on:change={(e) => (subjects = !e.target.value)}
          >
            <option value={policy.policy}>{policy.title} only</option>
            <option value="">select…</option>
          </select>
        </li>
        {#if subjects}
          <li>
            <ul>
              {#each $policies.filter((item) => item.amenity == policy.amenity) as item}
                <li>
                  <input
                    id="new-meter-tenant-subject-{item.policy}"
                    type="checkbox"
                    name="subject"
                    value={item.policy}
                    checked={item.policy == policy.policy}
                  /><label for="new-meter-tenant-subject-{item.policy}"
                    >{item.title}</label
                  >
                </li>
              {/each}
            </ul>
            <!-- <select id="new-meter-tenant-subject" name="subject" multiple>
              {#each $policies.filter(item => item.amenity == policy.amenity) as item}
              <option value="{item.policy}">{item.title}</option>
              {/each}
            </select> -->
          </li>
        {/if}
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Create Meter"}</button
      >
    </footer>
  </form>
{/if}
